p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 0;
  padding: 0;
  line-height: 1.1;
}

@media only screen and (max-width: 1440px) {
  html {
    font-size: 75%;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 60%;
  }
}

@media screen and (max-width: 425px) {
  p {
    line-height: 0.9;
  }
  html {
    font-size: 50%;
  }
}

@media screen and (max-width: 375px) {
  p {
    line-height: 0.9;
  }
  html {
    font-size: 50%;
  }
}
