@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

body {
  font-family: Montserrat !important;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  // background: #182443;
  #root {
    height: 100%;
    // flex: 1;
    // display: flex;
    // flex-direction: column;
   
    * {
      font-family: Montserrat;
      outline: none;
      box-sizing: border-box;
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
