.div {
  width: 100%;
  display: flex;
  align-items: center;
}

.item {
  width: 96%;
  height: 100%;
  padding-left: 5px;
}

.image {
  width: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.25);
  }
}

.alice-carousel__dots {
  display: none;
}

.alice-carousel__prev-btn {
  padding: 0;
  width: fit-content;
  position: absolute;
  transform: translate(-50%, -50%);
  left: -2%;
  top: 50%;
}

.alice-carousel__next-btn {
  padding: 0;
  width: fit-content;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 102%;
  top: 50%;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  font-size: 2rem;
}

.content-area {
  padding: 20px 25px;
  font-size: 0.8rem;
  background: #ffffff;
  h4 {
    margin: 0.7rem 0 0;
    color: rgba(10, 28, 47, 0.5);
    font-size: 0.8rem;
    letter-spacing: 0.1em;
    font-weight: 600;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 8px 0;
  }
}
