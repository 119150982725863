.left-div {
  z-index: 2;
  width: 20%;
  height: 100%;
  background: #182443;
  padding: 3.5% 3% 3.5% 2.5%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .logo-div {
    z-index: 1;
    width: 40%;
    height: 6%;
    top: 7%;
    background-image: url("../../Image/sp-logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    position: absolute;
    transition: all ease-in 0.2s;
    margin-left: 16px;
    animation: animatelogo 0.5s forwards;
    opacity: 0;
    transform: translateX(-100px);
    &:hover {
      transform: scale(1.07);
    }
    @keyframes animatelogo {
      0% {
        opacity: 0;
        transform: translateX(-100px);
      }
      25% {
        opacity: 0.25;
        transform: translateX(-75px);
      }
      50% {
        opacity: 0.5;
        transform: translateX(-50px);
      }
      75% {
        opacity: 0.75;
        transform: translateX(-25px);
      }
      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
  
  .options-div {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    gap: 5%;
    justify-content: center;
    transition: all ease-in 0.2s;
    animation: animatelogo 0.5s forwards;
    opacity: 0;
    transform: translateX(-100px);
    .options {
      display: flex;
      width: 100%;
      align-items: center;
      color: #ffffff;
      font-size: 1.5rem;
      padding-left: 16px;
      transition: all ease-in 0.2s;
      .box-div {
        display: flex;
        align-items: center;
        gap: 15px;
        .anime {
          cursor: pointer;
          transition: 1s;
          z-index: 20;
          overflow: hidden;
          position: relative;
          &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
          }
          &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
        img {
          width: 12px;
          transition: all ease-in 0.2s;
          cursor: pointer;
          &:hover {
            transform: scale(1.5);
          }
        }
      }
      @media screen and (max-width: 800px) and (min-width: 721px) {
        font-size: 1.1rem;
      }
      @media screen and (max-width: 1024px) and (min-width: 801px) {
        font-size: 1.2rem;
      }
    }
    .margin-class {
      display: flex;
      animation-name: showdiv;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
      overflow-y: scroll;

      @keyframes showdiv {
        0% {
          opacity: 0;
          transform: translateY(-50px);
        }
        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }

    .hidingclass {
      animation-name: hidediv;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;

      @keyframes hidediv {
        0% {
          opacity: 1;
          transform: translateY(0px);
        }
        100% {
          opacity: 0;
          transform: translateY(-50px);
        }
      }
    }
  }
}

@media screen and (max-width: 900px) and (min-width: 426px) {
  .left-div {
    .options-div {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  .left-div {
    .options-div {
      display: none;
    }
  }
}