.l-div {
  width: 100%;
  display: flex;
  align-items: center;
}

.l-item {
  width: 100%;
  height: 100%;
  padding: 10%;
//   border: 1px solid red;
}

.alice-carousel__dots {
  display: none;
}

.alice-carousel__prev-btn {
  padding: 0;
  width: fit-content;
  position: absolute;
  transform: translate(-50%, -50%);
  left: -2%;
  top: 50%;
}

.alice-carousel__next-btn {
  padding: 0;
  width: fit-content;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 102%;
  top: 50%;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  font-size: 2rem;
}

.l-content-area {
  padding: 20px 25px;
  font-size: 0.8rem;
  background: #ffffff;
}

.l-carousel__slide-item-title {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.l-carousel__slide-item-title h4 {
  text-align: center;
  color: #0a1c2f;
  text-transform: none;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: 0.05rem;
}
.l-carousel__slide-item-title ::after {
  align-items: center;
  background: rgba(black, 0.5);
  color: white;
  content: "read more";
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}

.l-carousel-slide-item__body {
  height: 58%;
  background: white;
}
.stars {
  text-align: center;
  font-size: 1.25rem;
  margin: 20px 0;
}
.by-txt {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #0a1c2f;
}
.l-content {
  font-size: 1rem;
  line-height: 1.6;
}
