.sp-container {
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  .div-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 100vh;
    border-bottom: 0.5px solid #efefef;
    .div1 {
      border-right: 0.5px solid #efefef;
      position: relative;
      .bottom-div {
        position: absolute;
        color: #182542;
        font-weight: 700;
        font-size: 1.5rem;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all ease 0.4s;
        cursor: pointer;
        &:hover {
          transform: translate(-50%, -125%);
        }
      }
    }
    .div2 {
      border-right: 0.5px solid #efefef;
      position: relative;
      .bottom-div {
        position: absolute;
        color: #182542;
        font-weight: 700;
        font-size: 1.5rem;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all ease 0.4s;
        cursor: pointer;
        &:hover {
          transform: translate(-50%, -125%);
        }
      }
    }
    .div3 {
      border-right: 0.5px solid #efefef;
      position: relative;
      .bottom-div {
        position: absolute;
        color: #182542;
        font-weight: 700;
        font-size: 1.5rem;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all ease 0.4s;
        cursor: pointer;
        &:hover {
          transform: translate(-50%, -125%);
        }
      }
    }
    .div4 {
      position: relative;
      .bottom-div {
        position: absolute;
        color: #182542;
        font-weight: 700;
        font-size: 1.5rem;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all ease 0.4s;
        cursor: pointer;
        &:hover {
          transform: translate(-50%, -125%);
        }
      }
    }
  }
  .image-container {
    width: 100%;
    padding: 0 5vw;
    margin: 5rem auto;
    .div-box {
      width: 100%;
      background: #6d6d98;
      display: flex;
      .image-1 {
        width: 60%;
        img {
          width: 100%;
        }
      }
      .text-box {
        width: 40%;
        padding: 5%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          font-size: 2rem;
          font-weight: 600;
          color: #ffffff;
        }
        button {
          color: #ffffff;
          font-size: 1rem;
          margin-top: 1rem;
          background: #f54985;
          border: none;
          width: 50%;
          height: 3rem;
          cursor: pointer;
          &:hover {
            background: #ba3463;
          }
        }
      }
    }
  }
  .subscribe-section {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    padding: 0 5vw;
    .btns {
      padding: 1% 2%;
      border: 0.5px solid black;
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
  .content-section {
    width: 100%;
    background: #f5f5f5;
    padding: 2vw 5vw;
    margin: auto;
    border-bottom: 0.5px solid #f5f5f5;
    .about {
      background: #e5e5e5;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 2vw;
      gap: 5%;
      .pic {
        width: 25%;
        border: 0.5px solid #efefef;
      }
      .about-txt {
        width: 70%;
      }
    }
    .section-title {
      margin-top: 2vw;
      margin-bottom: 2vw;
      text-transform: uppercase;
      font-weight: 600;
    }
    .carousel-section {
      width: 100%;
      height: max-content;
    }
  }
  .testimonial-section {
    width: 100%;
    background: #ffffff;
    padding: 5vw;
    .txt {
      text-align: center;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 5vw;
    }
  }
}

@media screen and (max-width: 900px) and (min-width: 426px) {
  .sp-container {
    width: 100%;
    .div-container {
      height: calc(100vh - 60px);
    }
  }

  .sp-container .image-container .div-box .text-box div {
    font-size: 1rem;
  }

  .sp-container .image-container .div-box .text-box button {
    font-size: 0.75rem;
    width: 60%;
  }
}

@media screen and (max-width: 425px) {
  .sp-container {
    width: 100%;
    .div-container {
      height: calc(100vh - 60px);
    }
  }

  .sp-container .image-container .div-box .text-box div {
    font-size: 1rem;
  }

  .sp-container .image-container .div-box .text-box button {
    font-size: 0.75rem;
    width: 60%;
  }
}
