.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
}

.mob-sidebar {
  display: none;
}

.sub-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  gap: 1rem;
  align-items: flex-start;
  overflow-y: scroll;
  .sub-opt {
    color: #ffffff;
    padding: 12px 30px 12px 16px;
    font-size: 1.25rem;
    transition: all ease-in 0.3s;
    cursor: pointer;
    &:hover {
      font-size: 1.35rem;
    }
    @media screen and (max-width: 1024px) and (min-width: 801px) {
      font-size: 1.1rem;
      &:hover {
        font-size: 1.25rem;
      }
    }
    @media screen and (max-width: 800px) and (min-width: 721px) {
      font-size: 0.8rem;
      &:hover {
        font-size: 1.05rem;
      }
    }
  }
}

.right-div {
  width: 80%;
  height: 100%;
  overflow-y: scroll;
  .cover-img {
    width: 100%;
    height: 65%;
    background-image: url("../../Image/shorupan.svg");
    background-repeat: no-repeat;
    background-size: cover;
    animation: zoomin 3.5s forwards;
    opacity: 0;

    @keyframes zoomin {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .name-details {
    width: 100%;
    height: 35%;
    display: flex;
    box-sizing: border-box;
    padding: 0 7%;
    gap: 5%;
    .name {
      width: 65%;
      height: 100%;
      padding-left: 2%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .font1 {
        font-size: 3rem;
        font-weight: 800;
        color: #182443;
        letter-spacing: 3px;
        opacity: 0;
        animation: textanimation 2s forwards;
        animation-delay: 0.3s;
      }
      .font2 {
        font-size: 5rem;
        font-weight: 800;
        color: #182443;
        letter-spacing: 5px;
        opacity: 0;
        animation-name: textanimation;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;
        @keyframes textanimation {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
      .ceo-text {
        display: flex;
        width: 100%;
        gap: 2.5%;
        align-items: center;
        margin-top: 5px;
        opacity: 0;
        animation: textanimation 2s forwards;
        animation-delay: 0.3s;
        p {
          font-weight: 400;
          font-size: 1.5rem;
        }
        .nvest-logo {
          cursor: pointer;
          transition: all ease-in 0.3s;
          width: 37%;
          &:hover {
            transform: scale(1.07);
          }
        }
      }
    }
    .buttons {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      align-items: flex-end;
      padding-right: 2%;
      .label1 {
        img {
          width: 24px;
          margin-right: 11px;
        }
        background: #ffffff;
        border: 0.5px solid #182443;
        border-radius: 15px;
        font-style: normal;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        height: 55px;
        width: 230px;
        max-width: 100%;
        color: #15172a;
        margin-bottom: 0;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        opacity: 0;
        cursor: pointer;
        transition: 1s;
        animation: btnanimation 2s forwards;
        animation-delay: 0.6s;
        &:hover {
          font-size: 17px;
          background: #fff;
          transform: scale(1.07);
        }
        &:after {
          background: #182443;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }
        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
        @media screen and (min-width: 1300px) {
          height: 65px;
        }
        @keyframes btnanimation {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
            transform: scale(1.1);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
      .label2 {
        background: #182443;
        border: 0.5px solid #182443;
        border-radius: 15px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        height: 55px;
        width: 230px;
        max-width: 100%;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        opacity: 0;
        transition: 1s;
        animation: btnanimation 2s forwards;
        animation-delay: 1.5s;
        &:hover {
          font-size: 17px;
          background: #182443;
          border: 0.5px solid #182443;
          transform: scale(1.07);
        }
        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }
        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }
        &:active {
          background: #182443;
          border: 0.5px solid #182443;
        }
        &:focus {
          background: #182443;
          border: 0.5px solid #182443;
          box-shadow: 0 0 0 0 #182443;
        }
        @media screen and (min-width: 1300px) {
          height: 65px;
        }
      }
    }
  }
  .headerImage {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: max-content;
    width: 100%;
    overflow: hidden;
    transition: all ease-in 0.4s;
    .images {
      position: relative;
      transform: scale(1.01);
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        transition: all ease-in 0.4s;
      }
      .overlay {
        position: absolute;
        inset: 0;
        background-color: black;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        transition: all ease-in 0.4s;
      }
      .overlayText {
        color: white;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 90%;
        left: 50%;
        font-weight: 700;
        font-size: 2rem;
      }
      &:hover img {
        transform: scale(1.02);
      }
      &:hover .overlay {
        transform: scale(1.02);
      }
    }
  }
  .connect-section {
    width: 100%;
    height: 75vh;
    display: flex;
    .text-div {
      width: 50%;
      height: 100%;
      border-right: 0.5px solid #e5e5e5;
      .text {
        gap: 4%;
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        font-size: 2rem;
        color: #182443;
        font-weight: 800;
        padding-left: 9.5%;
        img {
          width: 8%;
        }
      }
    }
    .icons-div {
      width: 50%;
      height: 100%;
      .icons {
        width: 100%;
        height: 50%;
        border-bottom: 0.5px solid #e5e5e5;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0% 10%;
        gap: 15%;
        img {
          width: 14%;
          cursor: pointer;
          transition: all ease-in 0.3s;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .icons1 {
        width: 100%;
        height: 50%;
        align-items: center;
        padding: 0% 10%;
        .flex-div {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: flex-start;
          gap: 15%;
          img {
            width: 14%;
            cursor: pointer;
            transition: all ease-in 0.3s;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
  .footer-div {
    width: 100%;
    .div1 {
      width: 100%;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      padding: 5%;
      border-top: 0.5px solid #e5e5e5;
      .footer-logo {
        height: 15%;
        img {
          width: 10%;
        }
      }
      .footer-details {
        width: 100%;
        height: 65%;
        display: flex;
        margin-top: 4rem;
        .contact-details {
          display: flex;
          flex-direction: column;
          width: 35%;
          gap: 1rem;
          .txt {
            font-size: 1.5rem;
            font-weight: 700;
          }
          .con-container {
            margin-top: 0.7rem;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            .cont-1 {
              font-size: 1.1rem;
              span {
                font-size: 1.2rem;
                font-weight: 700;
              }
            }
          }
        }
        .nav-details {
          border-left: 0.5px solid #e5e5e5;
          display: flex;
          flex-direction: column;
          width: 65%;
          height: fit-content;
          padding-left: 5%;
          gap: 1rem;
          .txt {
            font-size: 1.5rem;
            font-weight: 700;
            width: 100%;
          }
          .flex-class {
            display: flex;
            gap: 20%;
            margin-top: 0.7rem;
            .marginTop {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              .cont-1 {
                font-size: 1.1rem;
                cursor: pointer;
                transition: all ease 0.4s;
                &:hover {
                  scale: 1.1;
                }
              }
            }
          }
        }
      }
    }
    .div2 {
      width: 100%;
      background: #182443;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1rem;
      font-weight: 500;
      color: #ffffff;
      padding: 20px 0% 20px 5%;
    }
  }
}

.story-container {
  width: 80%;
  height: 100%;
  padding: 5% 0 0 5%;
  display: flex;
  gap: 5%;
  .stories-div {
    width: 60%;
    height: 100%;
    opacity: 0;
    animation: lefttoright ease-in 0.6s forwards;
    @keyframes lefttoright {
      0% {
        opacity: 0;
        transform: translateX(-100px);
      }
      25% {
        opacity: 0.25;
        transform: translateX(-75px);
      }
      50% {
        opacity: 0.5;
        transform: translateX(-50px);
      }
      75% {
        opacity: 0.75;
        transform: translateX(-25px);
      }
      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    }
    .story-text {
      font-size: 2.5rem;
      font-weight: 700;
      height: 8%;
      display: flex;
      align-items: center;
    }
    .story-content {
      width: 100%;
      height: calc(87% - 25px);
      overflow-y: scroll;
      font-size: 12px;
      line-height: 25px;
      margin-top: 25px;
      @media screen and (min-width: 1600px) {
        font-size: 15px;
        line-height: 30px;
      }
    }
  }
  .side-img {
    width: 40%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    opacity: 0;
    animation: righttoleft ease-in 0.6s forwards;
    @keyframes righttoleft {
      0% {
        opacity: 0;
        transform: translateX(100px);
      }
      25% {
        opacity: 0.25;
        transform: translateX(75px);
      }
      50% {
        opacity: 0.5;
        transform: translateX(50px);
      }
      75% {
        opacity: 0.75;
        transform: translateX(25px);
      }
      100% {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
}

.venture-container {
  width: 80%;
  height: 100%;
  .header {
    width: 100%;
    height: 25%;
    display: flex;
    padding-left: 5%;
    border-bottom: 0.5px solid #efefef;
    align-items: center;
    justify-content: flex-start;
    img {
      width: 40%;
    }
  }
  
  .brand-btns {
    width: 100%;
    height: 6%;
    display: flex;
    padding-left: 5%;
    border-bottom: 0.5px solid #efefef;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    gap: 0.75rem;
    align-items: center;
    justify-content: flex-start;
    .each-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1.5rem;
      font-size: 0.75rem;
      color: #182542;
      font-weight: 500;
      border: 0.5px solid #f3f4f5;
      cursor: pointer;
      border-radius: 15px;
    }
  }
  .companies-div {
    width: 100%;
    height: 75%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    align-content: flex-start;
    .ind-div {
      width: calc(100% / 3);
      height: 16rem;
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 1.25rem;
      font-weight: 700;
      color: #182542;
      z-index: 20;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      transition: all ease-in 0.4s;
      padding-left: 5%;
      border-bottom: 0.5px solid #efefef;
      border-right: 0.5px solid #efefef;
      &:hover .hover-class {
        left: 130%;
      }
      .hover-class {
        transition: all 0.5s ease;
        position: absolute;
        left: -60%;
        top: 0;
        bottom: 0;
        width: 15%;
        transform: skew(-40deg);
        background-color: #182443;
      }
    }
  }
  .companies-div1 {
    width: 100%;
    height: 69%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    align-content: flex-start;
    .ind-div {
      width: calc(100% / 3);
      height: 16rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1.5rem;
      font-size: 1.5rem;
      font-weight: 700;
      color: #182542;
      z-index: 20;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      transition: all ease-in 0.4s;
      padding-left: 5%;
      border-bottom: 0.5px solid #efefef;

      .margintop {
        align-items: center;
        transition: all ease-in 0.3s;
      }

      &:hover .margintop {
        margin-top: -16vh;
      }
      .hover-show {
        display: flex;
        font-size: 0.75rem;
        flex-direction: column;
        transition: all ease 0.4s;
        bottom: -100%;
        left: 0;
        position: absolute;
        padding: 0 10% 0 15%;
        img {
          width: 5%;
        }
        .zoom1 {
          margin-top: 20px;
          background: #f3f4f5;
          border: 0;
          border-radius: 30px;
          box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
          cursor: pointer;
          display: inline-block;
          height: 40px;
          overflow: hidden;
          transition: all 0.3s ease-out;
          width: 40px;
        }

        .check1-text {
          display: none;
        }

        .clickarrow-img {
          height: 20px;
          width: 20px;
        }
        .zoom2 {
          display: block;
          width: fit-content;
          &:hover {
            .check1-text {
              color: #182542;
              display: inline-flex;
              margin-left: 9px;
              margin-top: 5px;
              font-size: 15px;
              font-weight: 700;
            }
            .clickarrow-img {
              margin-left: 0.2rem;
              margin-top: 0.3rem;
              width: 20px;
            }
            .zoom1 {
              background: #fffbf0;
              height: 40px;
              text-align: center;
              width: 120px;
            }
          }
        }
      }
      &:hover .hover-show {
        bottom: 3vh;
      }
    }
  }
}

@media screen and (max-width: 2000px) and (min-width: 1600px) {
  .venture-container .companies-div1 .ind-div:hover .margintop {
    margin-top: -13vh;
  }
  .venture-container .companies-div1 .ind-div:hover .hover-show {
    bottom: 4vh;
  }
}

@media screen and (max-width: 2250px) and (min-width: 2001px) {
  .venture-container .companies-div1 .ind-div:hover .margintop {
    margin-top: -10vh;
  }
}

@media screen and (max-width: 2600px) and (min-width: 2251px) {
  .venture-container .companies-div1 .ind-div:hover .margintop {
    margin-top: -7vh;
  }
}

@media screen and (max-width: 900px) and (min-width: 426px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 21;
    transition: all 0.4s ease-in;
  }
  .mob-sidebar {
    display: flex;
    flex-direction: column;
    height: 50vh;
    width: 100%;
    position: absolute;
    z-index: 25;
    transition: all 0.4s ease-in;
    right: 0;
    top: -1000%;
    border-top: 0.5px solid #efefef;
    .heading-section {
      height: 15%;
      width: 100%;
      background: #ffffff;
      padding: 1.5% 6%;
      display: flex;
      justify-content: space-between;
      font-size: 1.75rem;
      font-weight: 300;
      // overflow-x: scroll;
      transition: all 0.4s ease-in;
      position: absolute;
      top: 0;
      align-items: center;
      #marker {
        position: absolute;
        height: 0.5px;
        left: 0;
        width: 0%;
        bottom: 17px;
        background: #182443;
        transition: 0.5s;
      }
      .each-heading {
        color: #182443;
      }
    }
    .sub-headings {
      height: 85%;
      width: 100%;
      position: absolute;
      background: #182443;
      color: #ffffff;
      display: flex;
      padding: 6%;
      align-items: center;
      transition: all 0.4s ease-in;
      transition-delay: 0.4s;
      top: -100%;
      .text {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 11%;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 1.75rem;
        font-weight: 500;
        overflow-y: scroll;
      }
    }
  }
  .mob-navbar {
    width: 100%;
    min-height: 60px;
    background-color: #ffffff;
    padding: 2% 6%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 25;
    border-bottom: 0.5px solid #efefef;
    .hamburger-menu {
      position: absolute;
      top: 0;
      bottom: 2%;
      right: 6%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .mob-logo {
      width: 75%;
      display: flex;
      justify-content: flex-start;
      img {
        width: 25%;
        cursor: pointer;
      }
    }
  }
  .right-div {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .cover-img {
      width: 100%;
      height: 65%;
      background-image: url("../../Image/coverpic-mob.png");
      background-repeat: no-repeat;
      background-size: cover;
      animation: zoomin 3.5s forwards;
      opacity: 0;
      background-position: 0%;

      @keyframes zoomin {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .name-details {
      width: 100%;
      height: 35%;
      padding: 7%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        width: 100%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0%;
        margin-left: 0rem;
        margin-top: 1rem;
        .font1 {
          font-size: 3.5rem;
          font-weight: 800;
          color: #182443;
          letter-spacing: 3px;
          opacity: 0;
          animation: textanimation 2s forwards;
          animation-delay: 0.3s;
        }
        .font2 {
          font-size: 6rem;
          font-weight: 800;
          color: #182443;
          letter-spacing: 3px;
          opacity: 0;
          animation-name: textanimation;
          animation-duration: 2s;
          animation-fill-mode: forwards;
          animation-delay: 0.3s;
          line-height: 50px;
          @keyframes textanimation {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }
        .ceo-text {
          p {
            font-weight: 500;
            font-size: 2.2rem;
          }
          .nvest-logo {
            width: 58%;
            margin-left: 0.75rem;
          }
        }
      }
      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 3%;
        padding-right: 0%;
        height: 25%;
        .label1 {
          img {
            width: 24px;
            margin-right: 11px;
          }
          background: #ffffff;
          border: 0.5px solid #182443;
          border-radius: 15px;
          font-style: normal;
          font-weight: 800;
          font-size: 1.25rem;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          height: 100%;
          width: 48.5%;
          color: #15172a;
          margin-bottom: 0;
          justify-content: center;
          z-index: 20;
          overflow: hidden;
          position: relative;
          opacity: 0;
          cursor: pointer;
          transition: 1s;
          animation: btnanimation 2s forwards;
          animation-delay: 0.6s;
          &:hover {
            font-size: 17px;
            background: #fff;
            transform: scale(1.07);
          }
          &:after {
            background: #182443;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
          }
          &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
          @keyframes btnanimation {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
        .label2 {
          background: #182443;
          border: 0.5px solid #182443;
          border-radius: 15px;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 800;
          font-size: 1.25rem;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
          height: 100%;
          width: 48.5%;
          justify-content: center;
          z-index: 20;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          opacity: 0;
          transition: 1s;
          animation: btnanimation 2s forwards;
          animation-delay: 1.5s;
          &:hover {
            font-size: 17px;
            background: #182443;
            border: 0.5px solid #182443;
            transform: scale(1.07);
          }
          &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
          }
          &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
          &:active {
            background: #182443;
            border: 0.5px solid #182443;
          }
          &:focus {
            background: #182443;
            border: 0.5px solid #182443;
            box-shadow: 0 0 0 0 #182443;
          }
        }
      }
    }
    .headerImage {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 60px);
      overflow: hidden;
      transition: all ease-in 0.4s;
      .images {
        position: relative;
        width: 100%;
        height: 33.33%;
        max-width: 100%;
        cursor: pointer;
        transform: none;
        img {
          object-fit: fill;
        }
        .overlay {
          position: absolute;
          inset: 0;
          background-color: black;
          opacity: 0.5;
          display: flex;
          justify-content: center;
          transition: all ease-in 0.4s;
          z-index: 0;
        }
        .overlayText {
          transform: none;
          top: 70%;
          left: 8%;
          font-size: 2.3rem;
          z-index: 1;
        }
      }
    }
    .mob-connect-section {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding: 14% 3% 10% 3%;
      gap: 1rem;
      .connect-txt {
        font-size: 2rem;
        font-weight: 700;
        padding-left: 6%;
      }
      .mob-icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;
        grid-row-gap: 3rem;
        margin-top: 2rem;
        .border-div {
          border: 0.5px solid #e5e5e5;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6% 4%;
          border-radius: 15px;
          width: 90%;
          height: 100%;
        }
        img {
          width: 90%;
          height: 90%;
          transition: all ease-in 0.3s;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .footer-div {
      .div1 {
        padding: 20% 8% 25% 8%;
        gap: 5%;
        .footer-logo {
          height: 40%;
          img {
            width: 35%;
          }
        }
        .footer-details {
          flex-direction: column;
          margin-top: 0%;
          .contact-details {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 6rem;
            .txt {
              font-size: 2.2rem;
              font-weight: 700;
            }
            .con-container {
              margin-top: 3rem;
              display: flex;
              flex-direction: column;
              gap: 2.5rem;
              .cont-1 {
                font-size: 1.5rem;
                span {
                  font-size: 1.5rem;
                  font-weight: 700;
                }
              }
            }
          }
          .nav-details {
            border-left: none;
            padding-left: 0%;
            margin-top: 6rem;
            width: 100%;
            .txt {
              font-size: 2.2rem;
              font-weight: 700;
              width: 100%;
            }
            .flex-class {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 0%;
              margin-top: 1.2rem;
              .marginTop {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin-top: 1.7rem;
                .cont-1 {
                  font-size: 1.7rem;
                }
              }
            }
          }
        }
      }
      .div2 {
        width: 100%;
        height: 10%;
        background: #182443;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.5rem;
        font-weight: 500;
        color: #ffffff;
        padding-left: 8%;
      }
    }
  }
  .story-container {
    width: 100%;
    height: calc(100% - 60px);
    padding: 7%;
    display: flex;
    grid-gap: 5%;
    gap: 5%;
    .stories-div {
      .story-content {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
  .venture-container {
    width: 100%;
    height: calc(100vh - 60px);
    .header {
      width: 100%;
      height: 30%;
      display: flex;
      padding-left: 0%;
      align-items: center;
      justify-content: center;
      img {
        width: 60%;
      }
    }
    .companies-div {
      height: 70%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      overflow-y: scroll;
      .ind-div {
        width: 100%;
        gap: 22px;
        padding-left: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 22rem;
        font-size: 1.3rem;
        .hover-class {
          display: none;
        }
        img {
          width: 30%;
        }
      }
    }
    .companies-div1 {
      height: 64%;
      .ind-div {
        width: 100%;
        height: 22rem;
        padding: 0% 5%;
        align-items: center;

        &:hover .margintop {
          margin-top: -11vh;
        }
        .hover-show {
          width: 100%;
          align-items: center;
        }
      }
    }
  }
  .venture-container .brand-btns .each-btn {
    font-size: 1rem;
  }
}

@media screen and (max-width: 425px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 21;
    transition: all 0.4s ease-in;
  }
  .mob-sidebar {
    display: flex;
    flex-direction: column;
    height: 50vh;
    width: 100%;
    position: absolute;
    z-index: 25;
    transition: all 0.4s ease-in;
    right: 0;
    top: -1000%;
    border-top: 0.5px solid #efefef;
    .heading-section {
      height: 15%;
      width: 100%;
      background: #ffffff;
      padding: 1.5% 6%;
      display: flex;
      justify-content: space-between;
      font-size: 1.75rem;
      font-weight: 300;
      // overflow-x: scroll;
      transition: all 0.4s ease-in;
      position: absolute;
      top: 0;
      align-items: center;
      #marker {
        position: absolute;
        height: 0.5px;
        left: 0;
        width: 0%;
        bottom: 17px;
        background: #182443;
        transition: 0.5s;
      }
      .each-heading {
        color: #182443;
      }
    }
    .sub-headings {
      height: 85%;
      width: 100%;
      position: absolute;
      background: #182443;
      color: #ffffff;
      display: flex;
      padding: 6%;
      align-items: center;
      transition: all 0.4s ease-in;
      transition-delay: 0.4s;
      top: -100%;
      .text {
        width: 100%;
        height: 100%;
        display: flex;
        gap: 11%;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 1.75rem;
        font-weight: 500;
        overflow-y: scroll;
      }
    }
  }
  .mob-navbar {
    width: 100%;
    min-height: 60px;
    background-color: #ffffff;
    padding: 2% 6%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 25;
    border-bottom: 0.5px solid #efefef;
    .hamburger-menu {
      position: absolute;
      top: 0;
      bottom: 2%;
      right: 6%;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .mob-logo {
      width: 75%;
      display: flex;
      justify-content: flex-start;
      img {
        width: 25%;
        cursor: pointer;
      }
    }
  }
  .right-div {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .cover-img {
      width: 100%;
      height: 65%;
      background-image: url("../../Image/coverpic-mob.png");
      background-repeat: no-repeat;
      background-size: cover;
      animation: zoomin 3.5s forwards;
      opacity: 0;
      background-position: 0%;

      @keyframes zoomin {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .name-details {
      width: 100%;
      height: 35%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0;
      gap: 0%;
      .name {
        width: 100%;
        height: 55%;
        padding: 0% 6%;
        gap: 1rem;
        .font1 {
          font-size: 4rem;
        }
        .font2 {
          font-size: 7rem;
          letter-spacing: 2px;
        }
        .ceo-text {
          margin-top: 0px;
          p {
            font-size: 2.5rem;
          }
          .nvest-logo {
            width: 52%;
          }
        }
      }
      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 0;
        padding-right: 0%;
        height: 25%;
        align-items: flex-start;
        .label1 {
          img {
            width: 24px;
            margin-right: 11px;
          }
          font-weight: 700;
          font-size: 1.75rem;
          width: 40%;
          height: 80%;
        }
        .label2 {
          font-weight: 700;
          font-size: 1.75rem;
          width: 40%;
          height: 80%;
        }
      }
    }
    .headerImage {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 60px);
      overflow: hidden;
      transition: all ease-in 0.4s;
      .images {
        position: relative;
        width: 100%;
        height: 33.33%;
        max-width: 100%;
        cursor: pointer;
        transform: none;
        img {
          object-fit: fill;
        }
        .overlay {
          position: absolute;
          inset: 0;
          background-color: black;
          opacity: 0.5;
          display: flex;
          justify-content: center;
          transition: all ease-in 0.4s;
          z-index: 0;
        }
        .overlayText {
          transform: none;
          top: 70%;
          left: 8%;
          font-size: 2.3rem;
          z-index: 1;
        }
      }
    }
    .mob-connect-section {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding: 6% 3%;
      gap: 1rem;
      .connect-txt {
        font-size: 2rem;
        font-weight: 700;
        padding-left: 6%;
      }
      .mob-icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-items: center;
        grid-row-gap: 2.5rem;
        margin-top: 0.5rem;
        .border-div {
          border: 0.5px solid #e5e5e5;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6% 4%;
          border-radius: 15px;
          width: 60%;
          height: 100%;
        }
        img {
          width: 80%;
          transition: all ease-in 0.3s;
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .footer-div {
      .div1 {
        padding: 8%;
        gap: 5%;
        .footer-logo {
          height: 12%;
          img {
            width: 25%;
          }
        }
        .footer-details {
          flex-direction: column;
          margin-top: 0%;
          .contact-details {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 3rem;
            .txt {
              font-size: 2rem;
              font-weight: 700;
            }
            .con-container {
              margin-top: 1.7rem;
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
              .cont-1 {
                font-size: 1.5rem;
                span {
                  font-size: 1.5rem;
                  font-weight: 700;
                }
              }
            }
          }
          .nav-details {
            border-left: none;
            padding-left: 0%;
            margin-top: 3.5rem;
            width: 100%;
            .txt {
              font-size: 2rem;
              font-weight: 700;
              width: 100%;
            }
            .flex-class {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 0%;
              margin-top: 0rem;
              .marginTop {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                margin-top: 1.7rem;
                .cont-1 {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
      .div2 {
        width: 100%;
        height: 12%;
        background: #182443;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.5rem;
        font-weight: 500;
        color: #ffffff;
        padding-left: 8%;
      }
    }
  }
  .story-container {
    width: 100%;
    height: calc(100vh - 60px);
    padding: 7%;
    display: flex;
    grid-gap: 5%;
    gap: 5%;
    .stories-div {
      .story-content {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }
  .venture-container {
    width: 100%;
    height: calc(100vh - 60px);
    .header {
      width: 100%;
      height: 30%;
      display: flex;
      padding-left: 0%;
      align-items: center;
      justify-content: center;
      img {
        width: 60%;
      }
    }
    .companies-div {
      height: 70%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      overflow-y: scroll;
      .ind-div {
        width: 100%;
        gap: 15px;
        padding-left: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 22rem;
        .hover-class {
          display: none;
        }
        img {
          width: 30%;
        }
      }
    }
    .companies-div1 {
      height: 64%;
      .ind-div {
        width: 100%;
        height: 22rem;
        padding: 0% 5%;
        align-items: center;

        &:hover .margintop {
          margin-top: -11vh;
        }
        .hover-show {
          width: 100%;
          align-items: center;
        }
      }
    }
  }
  .venture-container .brand-btns .each-btn {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 375px) {
  .right-div {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .cover-img {
      width: 100%;
      height: 65%;
      background-image: url("../../Image/coverpic-mob.png");
      background-repeat: no-repeat;
      background-size: cover;
      animation: zoomin 3.5s forwards;
      opacity: 0;

      @keyframes zoomin {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .name-details {
      width: 100%;
      height: 35%;
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        width: 100%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0%;
        .font1 {
          font-size: 3.25rem;
          letter-spacing: 0px;
          margin-top: 10px;
        }
        .font2 {
          font-size: 6.5rem;
          letter-spacing: 0px;
        }
        .ceo-text {
          p {
            font-size: 2rem;
          }
        }
      }
      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        padding-right: 0%;
        height: 25%;
        .label1 {
          img {
            width: 24px;
            margin-right: 11px;
          }
          background: #ffffff;
          border: 0.5px solid #182443;
          border-radius: 15px;
          font-style: normal;
          font-weight: 800;
          font-size: 1.5rem;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          height: 100%;
          width: 47%;
          color: #15172a;
          margin-bottom: 0;
          justify-content: center;
          z-index: 20;
          overflow: hidden;
          position: relative;
          opacity: 0;
          cursor: pointer;
          transition: 1s;
          animation: btnanimation 2s forwards;
          animation-delay: 0.6s;
          &:hover {
            font-size: 17px;
            background: #fff;
            transform: scale(1.07);
          }
          &:after {
            background: #182443;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
          }
          &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
          @media screen and (min-width: 1300px) {
            height: 65px;
          }
          @keyframes btnanimation {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
        .label2 {
          background: #182443;
          border: 0.5px solid #182443;
          border-radius: 15px;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 800;
          font-size: 1.5rem;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
          height: 100%;
          width: 47%;
          justify-content: center;
          z-index: 20;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          opacity: 0;
          transition: 1s;
          animation: btnanimation 2s forwards;
          animation-delay: 1.5s;
          &:hover {
            font-size: 17px;
            background: #182443;
            border: 0.5px solid #182443;
            transform: scale(1.07);
          }
          &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
          }
          &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
          &:active {
            background: #182443;
            border: 0.5px solid #182443;
          }
          &:focus {
            background: #182443;
            border: 0.5px solid #182443;
            box-shadow: 0 0 0 0 #182443;
          }
          @media screen and (min-width: 1300px) {
            height: 65px;
          }
        }
      }
    }
    .footer-div {
      .div1 {
        padding: 10% 8%;
        img {
          width: 35%;
        }
        .input-box {
          height: 20%;
          width: 90%;
        }
        .footer-details {
          .contact-details {
            margin-top: 4rem;
          }
        }
      }
      .div2 {
        font-size: 1.2rem;
        padding: 15px 0% 15px 8%;
      }
    }
  }
  .mob-sidebar {
    .heading-section {
      #marker {
        bottom: 15px;
      }
    }
  }
  .venture-container .brand-btns .each-btn {
    font-size: 1rem;
  }
}

@media screen and (max-width: 320px) {
  .mob-navbar {
    .hamburger-menu {
      img {
        width: 85%;
      }
    }
  }
  .right-div {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    .cover-img {
      width: 100%;
      height: 65%;
      background-image: url("../../Image/coverpic-mob.png");
      background-repeat: no-repeat;
      background-size: cover;
      animation: zoomin 3.5s forwards;
      opacity: 0;

      @keyframes zoomin {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .name-details {
      width: 100%;
      height: 35%;
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        width: 100%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0%;
        .font1 {
          letter-spacing: 1px;
          margin-top: 15px;
          font-size: 23px;
        }
        .font2 {
          font-size: 46px;
        }
        .ceo-text {
          p {
            font-weight: 400;
            font-size: 1.75rem;
          }
          .nvest-logo {
            width: 44%;
          }
        }
      }
      .buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        padding-right: 0%;
        height: 25%;
        .label1 {
          img {
            width: 24px;
            margin-right: 11px;
          }
          background: #ffffff;
          border: 0.5px solid #182443;
          border-radius: 15px;
          font-style: normal;
          font-weight: 800;
          font-size: 1.5rem;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          height: 100%;
          width: 47%;
          color: #15172a;
          margin-bottom: 0;
          justify-content: center;
          z-index: 20;
          overflow: hidden;
          position: relative;
          opacity: 0;
          cursor: pointer;
          transition: 1s;
          animation: btnanimation 2s forwards;
          animation-delay: 0.6s;
          &:hover {
            font-size: 17px;
            background: #fff;
            transform: scale(1.07);
          }
          &:after {
            background: #182443;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
          }
          &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
          @media screen and (min-width: 1300px) {
            height: 65px;
          }
          @keyframes btnanimation {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
              transform: scale(1.1);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
        .label2 {
          background: #182443;
          border: 0.5px solid #182443;
          border-radius: 15px;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 800;
          font-size: 1.5rem;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
          height: 100%;
          width: 47%;
          justify-content: center;
          z-index: 20;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          opacity: 0;
          transition: 1s;
          animation: btnanimation 2s forwards;
          animation-delay: 1.5s;
          &:hover {
            font-size: 17px;
            background: #182443;
            border: 0.5px solid #182443;
            transform: scale(1.07);
          }
          &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: 1;
            position: absolute;
            top: -50px;
            width: 50px;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transform: rotate(35deg);
            -ms-transform: rotate(35deg);
            transform: rotate(35deg);
            z-index: -10;
          }
          &:hover:after {
            left: 120%;
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
          &:active {
            background: #182443;
            border: 0.5px solid #182443;
          }
          &:focus {
            background: #182443;
            border: 0.5px solid #182443;
            box-shadow: 0 0 0 0 #182443;
          }
          @media screen and (min-width: 1300px) {
            height: 65px;
          }
        }
      }
    }
    .footer-div {
      .div1 {
        padding: 15% 8%;
        img {
          width: 35%;
        }
        .input-box {
          height: 20%;
          width: 90%;
        }
      }
      .div2 {
        font-size: 1.2rem;
        font-weight: 500;
        padding: 10px 0% 10px 8%;
      }
    }
  }
}
